<template>
  <div>
  <v-card flat>
    <v-card-title>{{ $t('policies') }} 
        <v-spacer></v-spacer>
        <v-tooltip v-if="seleted.length" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    depressed
                    class="ma-2 white--text"
                        @click="openPolice(seleted[0].id)"
                    v-bind="attrs"
                    v-on="on">
                    {{ $t('View User') }}
                    <v-icon
                        right
                        dark>
                            mdi-eye
                    </v-icon>
                </v-btn>
            </template>
            <span>{{ $t('View User') }}</span>
        </v-tooltip>
    </v-card-title>
  <v-card-text>
  <v-data-table 
  :loading="loading"
    :items="policies" 
    v-model="seleted"
    single-select show-select dense :headers="headers.police"></v-data-table>
  </v-card-text>
  </v-card>
    <show-police 
        v-if="showPolice.show" 
        :id="showPolice.id" 
        @close="showPolice.show = false">
    </show-police>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import showPolice from '../Policies/showPolice.vue';
export default {
  components: { showPolice },
name: "Policies",
data() {
    return {
        loading: false,
        policies: [],
        seleted: [],
        showPolice: {
            show: false,
            id: null
        },
        headers: {
        police: [
          {
            text: this.$t("policyNo"),
            sortable: false,
            value: "policyNo",
          },
          
          {
            text: this.$t("company_name"),
            sortable: false,
            value: "companyName",
          },
                    {
            text: this.$t("scheme"),
            sortable: false,
            value: "scheme.name",
          },
          
          {
            text: this.$t("startDate"),
            sortable: false,
            value: "startDate",
           
          },
          {
            text: this.$t("endDate"),
            sortable: false,
            value: "endDate",
           
          },
          {
            text: this.$t("paymentType"),
            sortable: false,
            value: "paymentType",
          },
           {
            text: this.$t("status"),
            sortable: false,
            value: "status",
          },
          {
            text: this.$t("total"),
            sortable: false,
            value: "total",
          },
          
        ],
         }
     
    }
},
computed: {
    ...mapState(['user']),
         auth() {
     return this.user != null ? this.user.type == 'Super-Admin' ?  true : false : false;
    },
},
methods: {
 ...mapActions("Api", ["getAll"]),
    getAllPolicies() {
try {
             this.loading = true;
             var v = this.auth ? 'policies' : `/companies/policies`;
        this.getAll(v)
          .then((res) => {
            console.log(res.data.data);
            this.policies = res.data.data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
        } catch (error) {
            console.log('tag', error);
        }
    },
    openPolice(value) {
        this.showPolice.id = value;
        this.showPolice.show = true;
    }
},
created() {
this.getAllPolicies();
}
}
</script>

<style>

</style>