<template>
  <div>
    <v-dialog  width="750" scrollable persistent v-model="display">
    <v-card flat v-if="loading">
<v-card-title></v-card-title>
<v-card-text>
 <div class="text-center" >
          <v-progress-circular indeterminate> </v-progress-circular>
        </div>
</v-card-text>
</v-card>
        <v-card v-else flat>
            <v-card-title>{{ $t('policyNo') }} : {{ police.policyNo }} / {{$t('User')}} : {{ police.user.fullName }} </v-card-title>
            <v-card-text>
            <v-img height="50" width="50" :src="police.companyLogo"></v-img>
            <v-simple-table  dense>
                <tbody>
                <tr>
                    <th>{{ $t("company_name") }}</th>
                    <td>{{ police.companyName }}</td>
                    <th>{{ $t("scheme") }}</th>
                    <td>{{ police.scheme.name }}</td>
                    <th>{{ $t("status") }}</th>
                    <td>{{ police.status }}</td>
                </tr>
                <tr>
                    <th>{{ $t("from") }}</th>
                    <td>{{ police.startDate }}</td>
                    <th>{{ $t("to") }}</th>
                    <td>{{ police.endDate }}</td>
                    <th>{{ $t("paymentType") }}</th>
                    <td>{{ police.paymentType }}</td>
                </tr>
                <tr>
                    <th>{{ $t("totalFees") }}</th>
                    <td>{{ police.totalFees }}</td>
                    <th>{{ $t("sub total") }}</th>
                    <td>{{ police.personTotal }}</td>
                    <th>{{ $t("total") }}</th>
                    <td>{{ police.total }}</td>
                </tr>
                </tbody>
          </v-simple-table>
           <v-tabs v-model="tab">
                <v-tab>{{ $t("familyMembers") }}</v-tab>
                <v-tab>{{ $t("description") }}</v-tab>
                <v-tab>{{ $t("Benfit") }}</v-tab>
                 <v-tab>{{ $t("Calculations") }}</v-tab>
                
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-data-table 
                            hide-default-footer 
                            disable-pagination
                            :headers="headers.familyMembers"
                            :items="police.familyMembers">
                                <template v-slot:item.avatar="{ item }">
                                    <v-avatar rounded>
                                        <v-img  :src="item.avatar"></v-img>
                                    </v-avatar>
                    
                                </template>
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                        <v-data-table
                        dense
                        hide-default-footer
                        disable-pagination
                        :items="police.scheme.descriptions"
                        :headers="headers.description"
                        >
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                        <v-data-table
                        dense
                        hide-default-footer
                        disable-pagination
                        :items="police.scheme.coverages"
                        :headers="headers.coverages"
                        >
                        </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                    <v-row>
                        <v-col>
                        <v-subheader  class="text-decoration-underline">{{ $t('fees') }}</v-subheader>
                            <v-simple-table  dense>
                                <tbody>
                                    <tr v-for="(fee , index) in police.fees" :key="index">
                                        <td>{{ fee.name }}</td>
                                        <td>{{ fee.price }}</td>
                                    </tr>
                                     <tr>
                                        <th class="text-decoration-underline">{{ $t("totalFees") }}</th>
                                        <th class="text-decoration-underline">{{ police.totalFees }}</th>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col>
                         <v-subheader  class="text-decoration-underline">{{ $t("Calculations") }}</v-subheader>
                          <v-divider ></v-divider>
                            <v-simple-table  dense>
                                <tbody>
                                    <tr>
                                        <th>{{ $t("totalFees") }}</th>
                                        <th>{{ police.totalFees }}</th>
                                    </tr>
                                    <tr>
                                        <th>{{ $t("sub total") }} ({{ $t('familyMemberTotal') }})</th>
                                        <th>{{ police.personTotal }}</th>
                                    </tr>
                                    <tr>
                                        <th class="text-decoration-underline">{{ $t("total") }}</th>
                                        <th class="text-decoration-underline">{{ police.total }}</th>
                                    </tr>
                                   <tr></tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                       
                    </v-tab-item>
            </v-tabs-items>
          </v-tabs>
            </v-card-text>
            <v-card-actions>
             <v-btn text @click="close">{{ $t("close") }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
name: "ShowPolice",
 props: ["id"],
data() {
    return {
        display: true,
        loading: false,
        tab: null,
        police: {},
        headers: {
            familyMembers: [
                {
                    text: this.$t("img"),
                    sortable: false,
                    value: "avatar",
                },
                {
                    text: this.$t("fullName"),
                    sortable: false,
                    value: "fullName",
                
                },
                {
                    text: this.$t("relation"),
                    sortable: false,
                    value: "relation",
                
                },
                {
                    text: this.$t("gender"),
                    sortable: false,
                    value: "gender",
                
                },
                {
                    text: this.$t("age"),
                    sortable: false,
                    value: "age",
                },
                {
                    text: this.$t("price"),
                    sortable: false,
                    value: "price",
                
                },
            ],
            coverages: [
                {
                    text: this.$t("name"),
                    width: "50%",
                    sortable: false,
                    value: "coverageName",
                },
                {
                    text: this.$t("description"),
                    sortable: false,
                    value: "description",
                    width: "50%",
                },
            ],
            description: [
                {
                    text: this.$t("name"),
                    sortable: false,
                    value: "name",
                },
                {
                    text: this.$t("description"),
                    sortable: false,
                    value: "description",
                }
            ],
        }
       
    }
},
computed: {
   
},
methods: {
    ...mapActions("Api", ["getAll"]),
    showPolice() {
        try {
             this.loading = true;
        this.getAll(`policies/${this.id}`)
          .then((res) => {
            console.log(res.data.data);
            this.police = res.data.data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
        } catch (error) {
            console.log('tag', error);
        }

    },
    close() {
      this.$emit("close");
    },
    
},
created() {
    this.showPolice();
}
}
</script>

<style>

</style>